<template>
  <div class="subscribe-distribution">
    <div>
        <div class="title">今日关注(人)</div>
        <div class="count">{{ data.todaySubscribe || 0 }}</div>
    </div>
    <div>
        <div class="title">粉丝总数(人)</div>
        <div class="count">{{ data.subscribe || 0 }}</div>
    </div>
    <div>
        <div class="title">已取关(人)</div>
        <div class="count">{{ data.unsubscribe || 0 }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeDistribution",
  data() {
    return {
      data: {
        unsubscribe: 0,
        subscribe: 0,
        todaySubscribe: 0,
      },
    };
  },
  methods: {
    async loadDistribution() {
      try {
        let data = await this.$http.doApi("fw-wx-user-subscribeDistribution");
        this.data = { ...data };
        console.log(this.data);
      } catch (e) {
        console.log(e);
      }
    },
  },
  created() {
    this.$eventBus.onAppChange(() => this.loadDistribution());
    this.loadDistribution();
  },
};
</script>

<style lang="less">
.subscribe-distribution {
  width: 100%;
  border: @hair-border-ccc;
  padding: 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  text-align: center;
  border-radius: 10px;

  .title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  .count {
    font-size: 36px;
    font-weight: 600;
  }

}
</style>