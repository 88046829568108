<template>
  <div class="wx-fans">
    <!-- 关注情况分布 -->
    <subscribe-distribution style="margin-bottom: 1rem;" />

    <!-- 查询表单 -->
    <div class="search-form">
      <el-form inline size="mini">
        <el-form-item label="昵称">
          <el-input v-model="searchForm.nicknameLike" clearable />
        </el-form-item>
        <el-form-item label="状态">
          <code-domain-select v-model="searchForm.subscribe" code-domain="wxUser.subscribe" ></code-domain-select>
        </el-form-item>
        <el-form-item label="关注时间">
          <el-date-picker
            v-model="subscribeTimeRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    
    <!-- 数据表格 -->
    <common-table ref="fansTable" :apiKey="tableConfig.apiKey" :columns="tableConfig.columns" :auto-load="true" :search-params="searchForm" />
  </div>
</template>
<script>
import config from './config'
import SubscribeDistribution from './SubscribeDistribution'

export default {
  name: "WxFans",
  data() {
    return {
      subscribeTimeRange:[],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              let today = new Date();
              let year = today.getFullYear();
              let month = today.getMonth() + 1;
              let day = today.getDate();
              let start = new Date(year + "-" + month + "-" + day + " 00:00:00");
              let end = new Date(year + "-" + month + "-" + day + " 23:59:59");
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      searchForm:{
        subscribe: 1
      },
        ...config
    };
  },
  methods: {
    doSearch() {
      this.$refs.fansTable.refresh();
    }
  },
  created() {
    this.$eventBus.$on('currentAppChanged', () => {
        this.$refs.fansTable.refresh();
    });
  },
  
  watch: {
    subscribeTimeRange(newVal) {
        if (newVal && newVal.length > 0) {
            this.searchForm.subscribeTimeGte = newVal[0];
            this.searchForm.subscribeTimeLte = newVal[1];
        } else {
            delete this.searchForm.subscribeTimeGte;
            delete this.searchForm.subscribeTimeLte
        }
        
    }
  },
  components: {
    'subscribe-distribution': SubscribeDistribution
  }
};
</script>

<style lang="less">
.wx-fans {
  padding: 1rem 1rem;
}
</style>