export default {
    tableConfig: {
        apiKey: 'fw-wx-user-list',
        columns: [
        {
            label: '头像',
            prop: 'headimgUrl',
            type: 'image',
            align: 'center'
        },
        {
            label: '昵称',
            prop: 'nickname'
        }, {
            label: '性别',
            prop: '_caption.sex'
        }, {
            label: '语言',
            prop: 'language'
        }, {
            label: '关注时间',
            prop: 'subscribeTime'
        },{
            label: '状态',
            prop: '_caption.subscribe'
        }, {
            label: '备注',
            prop: 'remark'
        }, {
            label: '标签',
            prop: 'tagIdList'
        }, {
            label: '用户来源',
            prop: '_caption.subscribeScene'
        }]
    }
}